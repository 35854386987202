import React from 'react'
import DemoButtons from "./DemoButtons";
import { Label } from "@fluentui/react";
import { Link } from "react-router-dom";

import Lightbox from "./Lightbox";
import HRfooter from './HRFooter';
export const Helpdeskhome = () => {
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };


    React.useEffect(() => {
        let currentMode = false;
        window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
          currentMode = event?.matches ? true : false;
        });
        var titleElement = document.querySelector('title');
        titleElement.textContent = 'Helpdesk 365';
        
        var favicon = document.querySelector('link[rel="icon"]');
        favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/HD365-White-9696_QVR6wHAAh.png?updatedAt=1707739822165": "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/HD365_MRfCAUv6pe.png?updatedAt=1686144360148")  
    }, [])
    

    return (
        <>


            <div
                style={{
                    position: "relative",
                }}
            >
                <div className="HR_MainHeaderSection HR_panel">
                    <div className="HR_FlexCenterSpcBetween">
                        <div className="HR_LogoSection">
                            <a
                                href="https://www.cubiclogics.com"
                                target="_blank"
                                rel="noreferrer"
                                className="HR_logowithTExt"
                            >
                                <img
                                    src={
                                        "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-200px/HD365_MRfCAUv6pe.png?updatedAt=1686144360148"
                                    }
                                    alt="Civic365"
                                />
                                <Label className='HDtext'>Helpdesk 365</Label>
                            </a>

                        </div>
                      
                        <div className="HR_FlexCenterSpcBetween" style={{ width: "10%" }}>
                            <div className="MB_button">
                                <div className="HR_DemoButtonsSection headerbutton">
                                <div style={{
                                    width: "8vw"
                                }} className="HR_PrimaryButton">
                                <Link  to='/helpdesk/aboutus'>
                                    <button>About us</button>
                                </Link>
                            </div>
                                   
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="HR_panel">
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
                    <div className="HR_Section1LeftSide">
                        <h1 className="HR_primaryclr  ">
                        Helpdesk 365 - Microsoft Teams & SharePoint Ticketing System
                        </h1>
                        <p className="HR_textclr">
                        Helpdesk 365 – Sharepoint ticketing system is a Microsoft ticketing system that is simple and customizable. This trouble ticket system ensures org to handle every issue within a set time frame. Assign every ticket to a specific team and have no confusion about which team should be working on it. Sharepoint helpdesk offers different ticket views to improve performance, category, time, status, or groups. Now you can use this tool for HR, IT and other teams too.
                        </p>

                        <div className="HR_OuterDemoButtons">
                            <div className="desktopView">
                                <DemoButtons />
                            </div>
                            <div className="HR_DemoButtons MobileView">
                                <div className="HR_PrimaryButton">
                                    <a
                                        href="https://request.civic365.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button>Citizen's Login</button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="HR_Section1RightSide">
                        <img
                            alt="MainImage"
                            src={
                                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HD_banner_B9LETdAzwr.png?updatedAt=1705488554594"
                            }
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
            <div className="HR_whitesection">
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
                    <div className="HR_Section1RightSide">
                        <img
                            alt="MainImage"
                            src={
                                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Focus-on_zIG8KsuUk.png?updatedAt=1705389176043"
                            }
                            loading="lazy"
                            onClick={() =>
                                openLightbox(
                                    "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Focus-on_zIG8KsuUk.png?updatedAt=1705389176043"
                                )
                            }
                        />
                        <Lightbox
                            isOpen={isLightboxOpen}
                            onClose={closeLightbox}
                            imageUrl={selectedImageUrl}
                        />
                    </div>
                    <div className="HR_Section1LeftSide1">
                        <h2 className="HR_primaryclr">Focus on what you need</h2>
                        <p className="HR_textclr">
                        HR365’s IT / HR help desk or cloud based ticketing system offers to organize the tickets based on priority, category, time, & status hence improving efficiency. Employees can submit requests to teams through sharepoint ticketing system’s customer portal, via email, or through an embedded web part. Support teams like IT, HR, legal, finance, etc. can accordingly work on these requests, track, and monitor using this microsoft 365 trouble ticket system.
                        </p>
                    </div>
                </div>
            </div>

            <div className="HR_panel">
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
                    <div className="HR_Section1LeftSide1">
                        <h2 className="HR_primaryclr">
                        Ticket Management - Do more with Helpdesk 365
                        </h2>
                        <p className="HR_textclr">
                        Now do more with advanced features of the IT / HR ticketing system like :
                        <li>Collaborate – Review, Consult & Escalate Tickets</li>
                        <li>Share ticket ownership with sub-tickets.</li>
                        <li>Track time spent on tickets.</li>
                        <li>Configurable new ticket form.</li>
                        <li>Add new fields with custom</li>
                        </p>
                    </div>
                    <div className="HR_Section1RightSide">
                        <img
                            alt="MainImage"
                            src={
                                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Comments_YxItfLWtiR.png?updatedAt=1705390283442"
                            }
                            loading="lazy"
                            onClick={() =>
                                openLightbox(
                                    "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Comments_YxItfLWtiR.png?updatedAt=1705390283442"
                                )
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="HR_whitesection">
                <h1 className="HR_primaryclr">
                Improve Efficiency with SLA on cloud-based ticketing system
                </h1>
                <p className="HR_textclr">
                With new features of Helpdesk 365, you can improve the efficiency and performance of agents and teams with Configurable SLA based on the request, department, services, etc.
                </p>
                <div className="HR_OuterDemoButtons HR_MainButton">
                    <DemoButtons />
                </div>
            </div>

            <HRfooter/>
        </>
    )
}
